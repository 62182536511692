import { Controller } from "stimulus"

const FILE_TYPE_DTA = { extension: "dta", kind: "dta" }
const FILE_TYPE_XML = { extension: "xml", kind: "sepa" }
const FILE_TYPE_UNKNOWN = "unknown"
const VALID_FILE_TYPES = [
  FILE_TYPE_DTA,
  FILE_TYPE_XML
]

const guessFileType = (filename) => {
  if (!filename || typeof filename !== "string") { return }

  const normalizedFilename = filename.toLowerCase().trim()

  // Easy case: The file simply ends with the correct suffix.
  for (const fileType of VALID_FILE_TYPES) {
    if (normalizedFilename.endsWith(fileType.extension)) { return fileType}
  }

  // MS Teams often appends timestamps to the end of file names, because screwing up the extension is obviously a good idea™.
  // So we check whether the file type is maybe embedded in the filename.
  for (const fileType of VALID_FILE_TYPES) {
    if (normalizedFilename.includes(fileType.extension)) { return fileType}
  }

  return FILE_TYPE_UNKNOWN
}

export default class extends Controller {
  static targets = ["fileInput", "kindSelect", "warningMixedFiles", "warningUnknownFiles"]

  validateFiles() {
    this.warningMixedFilesTarget.classList.add("d-none")
    this.warningUnknownFilesTarget.classList.add("d-none")

    const fileTypes = this.files.map((file) => guessFileType(file.name))
    const uniqueFileTypes = new Set(fileTypes)

    if (uniqueFileTypes.size > 1) {
      this.warningMixedFilesTarget.classList.remove("d-none")
      this.fileInputTarget.value = null
      this.kindSelectTarget.value = null
      return
    }

    if (uniqueFileTypes.has(FILE_TYPE_UNKNOWN)) {
      this.warningUnknownFilesTarget.classList.remove("d-none")
      this.kindSelectTarget.value = null
      return
    }

    this.kindSelectTarget.value = fileTypes[0].kind
  }

  get files() {
    if (!this.hasFileInputTarget) { return [] }

    return Array.from(this.fileInputTarget.files)
  }
}
