// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import BSN from "bootstrap.native/dist/bootstrap-native.esm.min.js"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "controllers"

import "../src/polyfills"

Rails.start()
ActiveStorage.start()

// Ensure BootstrapNative handles new elements after a Turbo Drive navigation.
// This doesn't work for alerts rendered after a form submittal, though, so we use Stimulus for this.
// TODO: Check if we can remove BSN entirely and just use Stimulus. I think we only use Dropdowns and alerts, so we should be fine.
document.addEventListener("turbo:load", () => { BSN.initCallback(document.body) })
