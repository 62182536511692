import { Controller } from "stimulus"
import { formEuro, toDelocalizedFloat } from '../src/currency-helpers'

const DEFAULT_PRECISION = 2

export default class extends Controller {
  static values = {
    emptyNulls: Boolean,
    precision: Number
  }

  formatAmount() {
    this.element.value = this.amount && this.amount.format()
  }

  get amount() {
    const delocalizedValue = toDelocalizedFloat(this.element.value)
    if (isNaN(delocalizedValue)) {
      return this.emptyNullsValue ? null : formEuro(0)
    }

    return formEuro(delocalizedValue, { precision: this.precision })
  }

  get precision() { return this.precisionValue || DEFAULT_PRECISION }
}
