import currency from 'currency.js'

const defaultConfig = {
  separator: '.',
  decimal: ',',
  symbol: "€",
  pattern: "# !",
  negativePattern: "-# !",
}

export function toDelocalizedFloat(valueString) {
  valueString = valueString.replaceAll(".", "").replaceAll(",", ".")
  return parseFloat(valueString)
}

export function euro(value) {
  return currency(value, defaultConfig)
}

export function formEuro(value, options) {
  options = options || {}

  return currency(value, {
    ...defaultConfig,
    pattern: "#",
    negativePattern: "-#",
    ...options
  })
}
