import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    selector: String,
  }

  triggerSubmit() {
    if (!this.target) { return }

    this.target.requestSubmit()
  }

  get target() {
    let target = null

    if (this.selectorValue) { try { target = document.querySelector(this.selectorValue) } catch(e) {} }
    if (!target) { target = this.element.closest("form") }
    return target
  }
}
