import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    activation: String,
    selector: String,
  }

  updateActivationState() {
    const shouldAddActivationState = this.activationValues.includes(this.element.value)
    this.targets.forEach(target => target.classList.toggle("is--disabled", !shouldAddActivationState))
  }

  get targets() { return document.querySelectorAll(this.selectorValue) }

  get activationValues() {
    if (!this.activationValue) { return [] }
    if (!this.activationValue.includes(",")) { return [this.activationValue] }

    return this.activationValue.split(",").map(value => value.trim())
  }
}
