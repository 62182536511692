import { Controller } from "stimulus"
import { formEuro, toDelocalizedFloat } from '../src/currency-helpers'

const COLLATERAL_PERCENTAGE = 0.9

export default class extends Controller {
  static targets = ["totalInput", "collateralInput"]

  updateCollateralAmount() {
    if (!this.hasCollateralInputTarget) { return }
    this.collateralInputTarget.value = this.collateralAmount.format()
  }

  get collateralAmount() { return formEuro(this.totalAmount).multiply(COLLATERAL_PERCENTAGE) }

  get totalAmount() {
    if (!this.hasTotalInputTarget) { return 0 }

    // Delocalize the input value
    const delocalizedValue = toDelocalizedFloat(this.totalInputTarget.value)
    if (isNaN(delocalizedValue)) { return formEuro(0) }
    if (delocalizedValue < 0) { return formEuro(0) }

    return formEuro(delocalizedValue)
  }
}
